import * as React from 'react';
import { graphql } from 'gatsby';
import { useOktaAuth } from '@okta/okta-react';
import Loading from '../../components/common/loading/Loading';

/**
 * This is the URL that Public Secure sends people to from the dashboard, or after confirming email for a new account.
 * Simply sign the user in
 */
const Start = props => {
  // IMPORTANT: Keep this conditional, or else the build will fail
  const { oktaAuth } = useOktaAuth
    ? useOktaAuth()
    : { authState: null, oktaAuth: null };
  if (oktaAuth) {
    oktaAuth.signInWithRedirect({
      originalUri: '/',
    });
  }
  return <Loading />;
};

export default Start;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
